import { createSelector } from '@ngrx/store';
import * as ListReducer from './electronic-tag.reducer';
import { selectFeatureState, State } from '../reducer';

const selectState = createSelector(selectFeatureState, (state: State) => state.electronicTagSettings);

const selectLoading = createSelector(selectState, ListReducer.getLoading);

const selectLoaded = createSelector(selectState, ListReducer.getLoaded);

const selectElectronicTagSettings = createSelector(selectState, ListReducer.getElectronicTagSettings)

const selectSelectedFormatsList = createSelector(selectState, ListReducer.getSelectFormatsList);

const selectAvailableFormatsList = createSelector(selectState, ListReducer.getAvailableFormatsList);

const selectSettings = createSelector(selectState, ListReducer.getSettings);

export const ElectronicTagListSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectElectronicTagSettings,
  selectSelectedFormatsList,
  selectAvailableFormatsList,
  selectSettings
};
