import { Inject, Injectable } from '@angular/core';
import { AuthFacade } from '@common/angular/auth';
import { SHOW_DEV_FEATURE } from '@common/angular/config';

import {
  CompanyPermissionsService,
  EnterprisePermissionsService,
  OperationalFeaturePermission,
  OperationPermissionsService
} from '@ifhms/admin/web/domain/permissions';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import {
  AbstractNavigationService,
  NavListBaseItem
} from '@ifhms/common/angular/features/navigation';
import { AuthAppLevelRoles, ContextLevel } from '@ifhms/models/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  of
} from 'rxjs';
import {
  AdminNavigationMenu,
  CompanyMenuItemsFn,
  FeedlotMenuItemsFn,
  getCompanyNavigationMenuItems,
  getOperationNavigationMenuItems
} from './interfaces';
import { getEnterpriseNavigationMenuItems } from './interfaces/enterprise-navigation.constants';
import {
  ModuleEnablementSettingsDto,
  OperationListItemDto
} from '@ifhms/models/admin';
import { CompaniesFacade } from '@ifhms/admin/domain/state/companies';
import { OperationsFacade } from '@ifhms/admin/web/domain/state/operations';
import {
  CommonPermissionsMap,
  CommonPermissionsService
} from '@common/angular/permissions';
import {
  ReportItemProperties,
  ReportTypeConfig
} from '@ifhms/common/angular/features/reports/shared';

@UntilDestroy()
@Injectable()
export class AdminNavigationService extends AbstractNavigationService {
  readonly translationScope = 'navigation';
  readonly translationNamespace = 'services.admin-navigation';

  showCompanyHeader$: Observable<boolean>;
  integrationSettings$ = this.operationFacade.moduleIntegrationSettings$;
  integrationChecks: ModuleEnablementSettingsDto | null;
  isAllIntegrationSettingsDisabled: boolean;
  feedlots$ = this.operationsFacade.feedlotOperation$;

  private showCompanyHeader = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SHOW_DEV_FEATURE) private showDevFeature: boolean,
    private companyFacade: CompanyFacade,
    private companiesFacade: CompaniesFacade,
    private operationFacade: OperationFacade,
    private operationsFacade: OperationsFacade,
    private operationPermissionsService: OperationPermissionsService,
    private companyPermissionsService: CompanyPermissionsService,
    private enterprisePermissionsService: EnterprisePermissionsService,
    private commonPermissionsService: CommonPermissionsService,
    private authFacade: AuthFacade
  ) {
    super();
    this.showCompanyHeader$ = this.showCompanyHeader.asObservable();
  }

  updateCompanyHeaderVisibity(isVisible: boolean): void {
    this.showCompanyHeader.next(isVisible);
  }

  getNavigationItems<AdminNavigationMenu>(
    menuType: AdminNavigationMenu
  ): Observable<SersiNavListItem[]> {
    let menuItems: Observable<SersiNavListItem[]>;
    switch (menuType) {
      case AdminNavigationMenu.Company:
        menuItems = this.withCompanyData(getCompanyNavigationMenuItems);
        break;
      case AdminNavigationMenu.Operation:
        menuItems = this.withOperationData(getOperationNavigationMenuItems);
        break;
      case AdminNavigationMenu.Enterprise:
        menuItems = this.withEnterpriseData();
        break;
      default:
        menuItems = of([]);
    }
    menuItems = menuItems.pipe(
      map((items) =>
        items.filter((item) =>
          item.isLegacyRoute ? !this.showDevFeature : true
        )
      )
    );
    return this.getLocalizedLabels(menuItems);
  }

  private withCompanyData(
    menuItemsFn: CompanyMenuItemsFn
  ): Observable<SersiNavListItem[]> {
    return this.companyFacade.companySlug$.pipe(
      map((companySlug) => {
        const menuItems = menuItemsFn(companySlug);
        return menuItems.filter((item) => {
          if (!item.requiredPermission) return true;

          return this.companyPermissionsService.hasPermissionSync(
            item.requiredPermission
          );
        });
      })
    );
  }

  private withOperationData(
    menuItemsFn: FeedlotMenuItemsFn
  ): Observable<SersiNavListItem[]> {
    return combineLatest([
      this.companyFacade.companySlug$,
      this.operationFacade.operationSlug$,
      this.authFacade.userRoles$,
      this.operationPermissionsService.permissions$,
      this.integrationSettings$
    ]).pipe(
      map(
        ([companySlug, feedlotSlug, roles, permissions, integrationsCheck]: [
          string,
          string,
          string[],
          CommonPermissionsMap | null,
          ModuleEnablementSettingsDto | null
        ]) => {
          const isEnterprise = roles?.includes(AuthAppLevelRoles.Enterprise);

          let menuItems = menuItemsFn(
            companySlug,
            feedlotSlug,
            this.showDevFeature
          );

          this.integrationChecks = integrationsCheck;
          this.checkAllIntegrationsSet(integrationsCheck);

          menuItems = this.setOperationsList(menuItems);
          menuItems = this.setFrmMenuItems(menuItems);
          menuItems = this.setProcurementItems(menuItems);
          menuItems = this.filterMenuItemsWithDevFlag(menuItems);

          menuItems.forEach((menuItem: NavListBaseItem) => {
            menuItem.subItems = this.checkSubItemsVisibility(
              menuItem,
              isEnterprise
            );
          });

          return menuItems.filter((item) =>
            this.checkItemVisibility(item, isEnterprise)
          );
        }
      )
    );
  }

  private checkSubItemsVisibility(
    item: NavListBaseItem,
    isEnterprise: boolean
  ): NavListBaseItem[] | undefined {
    if (item.subItems) {
      return item.subItems?.filter((i: NavListBaseItem) => {
        if (i.subItems) {
          i.subItems = i.subItems.filter((subItem: NavListBaseItem) =>
            this.checkItemVisibility(subItem, isEnterprise)
          );

          if (i.subItems && i.subItems.length < 1) {
            i.hide = true;
          } else {
            i.subItems.forEach((subSubItem: NavListBaseItem) => {
              if (subSubItem.subItems && subSubItem.subItems.length > 0) {
                subSubItem.subItems = subSubItem.subItems.filter(
                  (i: NavListBaseItem) =>
                    this.checkItemVisibility(i, isEnterprise)
                );
              }
            });
          }
        }
        return this.checkItemVisibility(i, isEnterprise);
      });
    } else {
      return [];
    }
  }

  private checkItemVisibility(
    item: NavListBaseItem,
    isEnterprise: boolean
  ): boolean {
    if (!this.showDevFeature && item.isVisible === false) return false;
    if (item.hide === true) return false;
    if (
      item.requiredModuleAccess &&
      !this.operationPermissionsService.hasPermissionSync(
        item.requiredModuleAccess
      )
    )
      return false;
    if (!item.permissionList) return true;
    if (item.permissionList.some((permission) => permission === 'enterprise')) {
      return isEnterprise;
    }
    return item.permissionList.some((permission) =>
      this.operationPermissionsService.hasPermissionSync(
        permission as OperationalFeaturePermission
      )
    );
  }

  private filterMenuItemsWithDevFlag(
    items: NavListBaseItem[]
  ): NavListBaseItem[] {
    return items.filter((item) => {
      if (!this.showDevFeature && item.isVisible === false) return false;
      if (item.hide === true) return false;
      if (item.subItems) {
        item.subItems = this.filterMenuItems(item.subItems);
        return item.subItems.length > 0;
      }
      return true;
    });
  }

  private filterMenuItems(items: NavListBaseItem[]): NavListBaseItem[] {
    return items.filter((item) => {
      if (item.isVisible === false) return false;
      if (item.hide === true) return false;
      if (item.subItems) {
        item.subItems = this.filterMenuItems(item.subItems);
        return item.subItems.length > 0;
      }
      return true;
    });
  }

  private withEnterpriseData(): Observable<SersiNavListItem[]> {
    return this.enterprisePermissionsService.permissions$.pipe(
      filter((x) => !!x),
      map(() => getEnterpriseNavigationMenuItems()),
      map((items) => this.filterEnterpriseMenuItems(items))
    );
  }

  private filterEnterpriseMenuItems(
    items: SersiNavListItem[]
  ): SersiNavListItem[] {
    return items.filter((item) => {
      if (!this.showDevFeature && item.isVisible === false) return false;
      if (item.requiredPermission) {
        return this.enterprisePermissionsService.hasPermissionSync(
          item.requiredPermission
        );
      }
      if (item.subItems) {
        item.subItems = this.filterEnterpriseMenuItems(item.subItems);
        return item.subItems.length > 0;
      }
      return true;
    });
  }

  private checkAllIntegrationsSet(
    integrationChecks: ModuleEnablementSettingsDto | null
  ): void {
    if (integrationChecks) {
      const integrations = [
        integrationChecks.isCciaEnabled,
        integrationChecks.isProFeederEnabled,
        integrationChecks.isTurnKeyEnabled
      ];
      this.isAllIntegrationSettingsDisabled = integrations.every((val) => !val);
    }
  }

  private setOperationsList(menuItems: NavListBaseItem[]): NavListBaseItem[] {
    const operationListItem = menuItems.find(
      (item) => item.key === 'operation-list'
    );
    if (operationListItem) {
      this.operationsFacade.operations$
        .pipe(first())
        .subscribe((operations: OperationListItemDto[] | null) => {
          if (operations && operations.length === 1) {
            operationListItem.hide = true;
          }
        });
    }
    return menuItems;
  }

  private setFrmMenuItems(menuItems: NavListBaseItem[]): NavListBaseItem[] {
    //TODO WILL NEED TO SET REST OF PERMISSIONS HERE FOR FRM
    const frmMenuItem = menuItems.find((item) => item.key === 'frm');

    if (frmMenuItem && frmMenuItem.subItems) {
      frmMenuItem.hide = this.integrationChecks?.isFrmEnabled === false;

      this.setIntegrationsMenuItems(frmMenuItem);
      this.setProtocolMgmtItem(frmMenuItem);
      this.setReportItems(frmMenuItem, ContextLevel.Operation);
    }
    return menuItems;
  }

  private setProcurementItems(menuItems: NavListBaseItem[]): NavListBaseItem[] {
    const procurementMenuItem = menuItems.find(
      (item) => item.key === 'procurement'
    );

    if (procurementMenuItem) {
      procurementMenuItem.hide =
        this.integrationChecks?.isProcurementsEnabled === false;
    }

    return menuItems;
  }

  private setIntegrationsMenuItems(frmMenuItem: NavListBaseItem): void {
    if (frmMenuItem.subItems) {
      const integrationsMenuItem = frmMenuItem.subItems.find(
        (item) => item.key === 'integrations'
      );

      if (integrationsMenuItem) {
        integrationsMenuItem.hide = this.isAllIntegrationSettingsDisabled;

        integrationsMenuItem?.subItems?.forEach(
          (subMenuItem: NavListBaseItem) => {
            switch (subMenuItem.key) {
              case 'ccia':
                subMenuItem.hide =
                  this.integrationChecks?.isCciaEnabled === false;
                return;
              case 'turnkey':
                subMenuItem.hide =
                  this.integrationChecks?.isTurnKeyEnabled === false;
                return;
              case 'pro-feeder':
                subMenuItem.hide =
                  this.integrationChecks?.isProFeederEnabled === false;
                return;
              default:
                return;
            }
          }
        );
      }
    }
  }

  private setProtocolMgmtItem(frmMenuItem: NavListBaseItem): void {
    if (frmMenuItem.subItems) {
      const protocolMgmtMenuItem = frmMenuItem.subItems.find(
        (item) => item.key === 'protocols'
      );

      if (protocolMgmtMenuItem) {
        this.authFacade.userRoles$
          .pipe(first())
          .subscribe((roles: string[]) => {
            const isEnterprise = roles?.includes(AuthAppLevelRoles.Enterprise);

            protocolMgmtMenuItem.hide = !isEnterprise;
          });
      }

      const settingsMenuItem = frmMenuItem.subItems.find(
        (item) => item.key === 'settings'
      );

      if (settingsMenuItem) {
        if (settingsMenuItem.subItems) {
          const woAndEventsMenuItem = settingsMenuItem.subItems.find(
            (item) => item.key === 'wo-and-events'
          );

          if (woAndEventsMenuItem && woAndEventsMenuItem.subItems) {
            const treatmentMenuItem = woAndEventsMenuItem.subItems.find(
              (item) => item.key === 'treatment'
            );

            if (treatmentMenuItem) {
              this.authFacade.userRoles$
                .pipe(first())
                .subscribe((roles: string[]) => {
                  const isEnterprise = roles?.includes(
                    AuthAppLevelRoles.Enterprise
                  );

                  treatmentMenuItem.hide = !isEnterprise;
                });
            }
          }
        }
      }
    }
  }

  private setReportItems(
    frmMenuItem: NavListBaseItem,
    contextLevel: ContextLevel
  ): void {
    if (frmMenuItem.subItems) {
      const reportsMenuItem = frmMenuItem.subItems.find(
        (item) => item.key === 'reports'
      );

      if (reportsMenuItem) {
        reportsMenuItem.subItems?.forEach((subItem): void => {
          subItem.subItems?.forEach((subSubItem): void => {
            subSubItem.hide = this.showItem(subSubItem, contextLevel) === false;
          });
        });
      }
    }
  }

  private showItem(
    menuItem: NavListBaseItem,
    contextLevel: ContextLevel
  ): boolean {
    const report = this.getReportType(menuItem.key);

    if (report) {
      return this.isReportTypeEnabled(report, contextLevel);
    } else {
      return false;
    }
  }

  getReportType(key: string): ReportItemProperties | undefined {
    return Object.values(ReportTypeConfig).find((reportKey) =>
      reportKey.type.includes(key)
    );
  }

  private isReportTypeEnabled(
    report: ReportItemProperties,
    contextLevel: ContextLevel
  ): boolean {
    const isEnabledForContext = report.enabledContext.includes(contextLevel);
    const isEnabledForEnv = !report.isDevFeature || this.showDevFeature;
    const hasPermission =
      report.permission &&
      (contextLevel === ContextLevel.Operation ||
        contextLevel === ContextLevel.Feedlot)
        ? this.commonPermissionsService.hasPermissionSync(report.permission)
        : true;

    if (!isEnabledForEnv) return false;

    return isEnabledForContext && hasPermission;
  }
}
