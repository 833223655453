import { createReducer, on } from '@ngrx/store';
import { ElectronicTagSettingsActions } from './electronic-tag.actions';
import { ElectronicDefinedSettingsDto, ElectronicTagDto, ElectronicTagSettingsDto } from '@ifhms/models/admin';

export interface State {
  loading: boolean;
  loaded: boolean;
  availableFormatsList: ElectronicTagDto[];
  electronicTagSettings: ElectronicTagSettingsDto;
}

const initialState: State = {
  loading: false,
  loaded: false,
  availableFormatsList: [],
  electronicTagSettings: {
    nationalIdTypes: [],
    settings: {
      useNationalIdSettings: false,
      blockNationalIdError: false,
      displayNationalIdWarningMessage: false
    }
  }
};

export const reducer = createReducer(
  initialState,
  on(ElectronicTagSettingsActions.getElectronicTagSettings,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),

  on(ElectronicTagSettingsActions.getAvailableFormats,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),

  on(ElectronicTagSettingsActions.update,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),

  on(ElectronicTagSettingsActions.getElectronicTagSettingsSuccess,
    ElectronicTagSettingsActions.updateSuccess,
    (state, { electronicTagSettings }) => ({
      ...state,
      electronicTagSettings: {
        nationalIdTypes: electronicTagSettings.nationalIdTypes,
        settings: electronicTagSettings.settings
      },
      loading: false,
      loaded: true
    })),

  on(ElectronicTagSettingsActions.getAvailableFormatsSuccess,
    (state, { availableFormatsList }) => ({
      ...state,
      availableFormatsList: availableFormatsList,
      loading: false,
      loaded: true
    })),

  on(ElectronicTagSettingsActions.updateListViewAdd,
    ElectronicTagSettingsActions.updateListViewRemove,
    (state, { updatedFormatLists }) => ({
      ...state,
      availableFormatsList: updatedFormatLists.availableFormatList,
      electronicTagSettings: {
        nationalIdTypes: updatedFormatLists.selectedFormatList,
        settings: state.electronicTagSettings.settings
      }
    })),

  on(ElectronicTagSettingsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(ElectronicTagSettingsActions.reset, () => initialState)
);

export const getElectronicTagSettings = (state: State): ElectronicTagSettingsDto => state.electronicTagSettings
export const getSelectFormatsList = (state: State): ElectronicTagDto[] => state.electronicTagSettings?.nationalIdTypes;
export const getAvailableFormatsList = (state: State): ElectronicTagDto[] => state.availableFormatsList;
export const getSettings = (state: State): ElectronicDefinedSettingsDto => state.electronicTagSettings?.settings;

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
