export enum ReportItemType {
  AnimalTreatmentHistory = 'animal-treatment-history',
  ArrivalWorkOrder = 'arrival-work-order',
  CattleMovement = 'cattle-movement',
  CurrentProductPricing = 'current-product-pricing',
  DailyTXHistory = 'daily-tx-history',
  EventDetails = 'event-details',
  HospitalOccupancy = 'hospital-occupancy',
  LotExpenseSummary = 'lot-expense-summary',
  LotHealthSummary = 'lot-health-summary',
  LotOtherDiseasesDetail = 'lot-other-diseases-detail',
  LotTransferWorkOrder = 'lot-transfer-work-order',
  LotTreatmentHistory = 'lot-treatment-history',
  LotUFDetail = 'lot-uf-detail-report',
  MovementWorkOrder = 'movement-work-order',
  OtherDiseasesMgmt = 'other-diseases-management',
  LotInventory = 'lot-inventory',
  PenInventory = 'pen-inventory',
  PostMortemDetail = 'post-mortem-detail',
  PostMortemDetailByDate = 'post-mortem-detail-by-date',
  RailDetailByDate = 'rail-detail-by-date',
  ProductOnHand = 'product-on-hand',
  ProductUsage = 'product-usage',
  RailWorkOrder = 'rail-work-order',
  RehandlingWorkOrder = 'rehandling-work-order',
  ReconciledPhysicalInventoryValue = 'reconciled-physical-inventory-value',
  ShipmentWorkOrder = 'shipment-work-order',
  TagAvailability = 'tag-availability',
  UFManagement = 'uf-management',
  WithdrawalSummaryReport = 'withdrawal-summary-report',
  TreatmentEvent = 'treatment-event',
  PostMortemEvent = 'post-mortem-event',
  ExportWeightByWorkOrder = 'export-weight-by-work-order',
  ExportWeightByTag = 'export-weight-by-tag',
  ExportBirthdayByTag = 'export-birthday-by-tag',
  ExportCciaData = 'export-ccia-data',
  ExportCustomFieldsByTag = 'export-custom-fields-by-tag',
  ExportLotBilling = 'export-lot-billing'
}
