import { ReportPermissionsEnum } from '@ifhms/common/angular/interfaces';
import { ContextLevel } from '@ifhms/models/shared';
import { ReportCategory } from './report-category.enum';
import { ReportItemsProperties } from './report-item-properties.type';
import { ReportItemType } from './report-item-type.enum';

export const ReportTypeConfig: ReportItemsProperties = {
  [ReportItemType.AnimalTreatmentHistory]: {
    apiUrl: '/AnimalTreatmentHistory',
    type: ReportItemType.AnimalTreatmentHistory,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsAnimalTreatmentHistory
  },
  [ReportItemType.LotTreatmentHistory]: {
    apiUrl: '/LotTreatmentHistory',
    type: ReportItemType.LotTreatmentHistory,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsAnimalTreatmentHistory
  },
  [ReportItemType.ArrivalWorkOrder]: {
    apiUrl: '/ArrivalWorkOrder',
    type: ReportItemType.ArrivalWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsArrivalWorkOrder
  },
  [ReportItemType.CattleMovement]: {
    apiUrl: '/CattleMovement',
    type: ReportItemType.CattleMovement,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsCattleMovement
  },
  [ReportItemType.CurrentProductPricing]: {
    apiUrl: '/CurrentProductPricing',
    type: ReportItemType.CurrentProductPricing,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.ProductInventory,
    permission: ReportPermissionsEnum.ReportsCurrentProductPricing
  },
  [ReportItemType.DailyTXHistory]: {
    apiUrl: '/DailyTXHistory',
    type: ReportItemType.DailyTXHistory,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsDailyTreatmentHistoryByFacility,
    isDevFeature: true
  },
  [ReportItemType.EventDetails]: {
    apiUrl: '/EventDetails',
    type: ReportItemType.EventDetails,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsEventDetails
  },
  [ReportItemType.HospitalOccupancy]: {
    apiUrl: '/HospitalOccupancy',
    type: ReportItemType.HospitalOccupancy,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsHospitalOccupancy
  },
  [ReportItemType.LotExpenseSummary]: {
    apiUrl: '/LotExpensesSummary',
    type: ReportItemType.LotExpenseSummary,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsLotExpensesSummary
  },
  [ReportItemType.LotHealthSummary]: {
    apiUrl: '/LotHealthSummary',
    type: ReportItemType.LotHealthSummary,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsLotHealthSummary
  },
  [ReportItemType.LotOtherDiseasesDetail]: {
    apiUrl: '/LotOtherDiseasesDetail',
    type: ReportItemType.LotOtherDiseasesDetail,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsLotOtherDiseasesDetail
  },
  [ReportItemType.LotTransferWorkOrder]: {
    apiUrl: '/LotTransferWorkOrder',
    type: ReportItemType.LotTransferWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsLotTransferWorkOrder
  },
  [ReportItemType.LotUFDetail]: {
    apiUrl: '/LotUFDetail',
    type: ReportItemType.LotUFDetail,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsLotUFDetail
  },
  [ReportItemType.MovementWorkOrder]: {
    apiUrl: '/MovementWorkOrder',
    type: ReportItemType.MovementWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsMovementWorkOrder
  },
  [ReportItemType.OtherDiseasesMgmt]: {
    apiUrl: '/OtherDiseasesManagement',
    type: ReportItemType.OtherDiseasesMgmt,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsOtherDiseasesManagement
  },
  [ReportItemType.LotInventory]: {
    apiUrl: '/LotInventory',
    type: ReportItemType.LotInventory,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsLotInventoryReport
  },
  [ReportItemType.PenInventory]: {
    apiUrl: '/PenInventory',
    type: ReportItemType.PenInventory,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsPenInventory
  },
  [ReportItemType.PostMortemDetail]: {
    apiUrl: '/PostMortemDetail',
    type: ReportItemType.PostMortemDetail,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsPostMortemDetail
  },
  [ReportItemType.PostMortemDetailByDate]: {
    apiUrl: '/PostMortemDetailDate',
    type: ReportItemType.PostMortemDetailByDate,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsPostMortemDetailByDate
  },
  [ReportItemType.RailDetailByDate]: {
    apiUrl: '/RailDetailDate',
    type: ReportItemType.RailDetailByDate,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsRailDetail
  },
  [ReportItemType.ProductOnHand]: {
    apiUrl: '/ProductOnHand',
    type: ReportItemType.ProductOnHand,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.ProductInventory,
    permission: ReportPermissionsEnum.ReportsProductOnHand
  },
  [ReportItemType.ProductUsage]: {
    apiUrl: '/ProductUsage',
    type: ReportItemType.ProductUsage,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.ProductInventory,
    permission: ReportPermissionsEnum.ReportsProductUsage
  },
  [ReportItemType.RailWorkOrder]: {
    apiUrl: '/RailWorkOrder',
    type: ReportItemType.RailWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsRailWorkOrder
  },
  [ReportItemType.RehandlingWorkOrder]: {
    apiUrl: '/RehandlingWorkOrder',
    type: ReportItemType.RehandlingWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsReHandlingWorkOrder
  },
  [ReportItemType.ReconciledPhysicalInventoryValue]: {
    apiUrl: '/ProductReconcilationInventoryValue',
    type: ReportItemType.ReconciledPhysicalInventoryValue,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.ProductInventory,
    permission: ReportPermissionsEnum.ReportsReconciledPhysicalInventoryValue
  },
  [ReportItemType.ShipmentWorkOrder]: {
    apiUrl: '/ShipmentWorkOrder',
    type: ReportItemType.ShipmentWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders,
    permission: ReportPermissionsEnum.ReportsShipmentWorkOrder
  },
  [ReportItemType.TagAvailability]: {
    apiUrl: '/TagAvailability',
    type: ReportItemType.TagAvailability,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsTagAvailability
  },
  [ReportItemType.UFManagement]: {
    apiUrl: '/UFManagement',
    type: ReportItemType.UFManagement,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.AnimalHealth,
    permission: ReportPermissionsEnum.ReportsUFManagement
  },
  [ReportItemType.WithdrawalSummaryReport]: {
    apiUrl: '/WithdrawalSummary',
    type: ReportItemType.WithdrawalSummaryReport,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.Operational,
    permission: ReportPermissionsEnum.ReportsWithdrawal
  },
  [ReportItemType.TreatmentEvent]: {
    apiUrl: '/TreatmentEvent',
    type: ReportItemType.TreatmentEvent,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders
  },
  [ReportItemType.PostMortemEvent]: {
    apiUrl: '/PostMortemEvent',
    type: ReportItemType.PostMortemEvent,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation, ContextLevel.Feedlot],
    category: ReportCategory.WorkOrders
  },
  [ReportItemType.ExportWeightByWorkOrder]: {
    apiUrl: '/ExportWeightByWorkOrder',
    type: ReportItemType.ExportWeightByWorkOrder,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  },
  [ReportItemType.ExportWeightByTag]: {
    apiUrl: '/ExportWeightByTag',
    type: ReportItemType.ExportWeightByTag,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  },
  [ReportItemType.ExportBirthdayByTag]: {
    apiUrl: '/ExportBirthdayByTag',
    type: ReportItemType.ExportBirthdayByTag,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  },
  [ReportItemType.ExportCciaData]: {
    apiUrl: '/ExportCCIAData',
    type: ReportItemType.ExportCciaData,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  },
  [ReportItemType.ExportCustomFieldsByTag]: {
    apiUrl: '/ExportCustomFieldsByTag',
    type: ReportItemType.ExportCustomFieldsByTag,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  },
  [ReportItemType.ExportLotBilling]: {
    apiUrl: '/ExportLotBilling',
    type: ReportItemType.ExportLotBilling,
    enabledContext: [ContextLevel.Company, ContextLevel.Operation],
    category: ReportCategory.Exports
  }
}
