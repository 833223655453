import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';

import {
  AUTH_CONFIG_API_URLS,
  AUTH_CONFIG_SERVER_URL,
  CommonAngularAuthModule
} from '@common/angular/auth';
import {
  APP_CONFIG,
  getAnimalsApiUrl,
  getApiUrl,
  getAuthServerUrls,
  getConfig,
  getReportApiUrl,
  getReportSelectListApiUrl,
  getWindow,
  SHOW_DEV_FEATURE,
  WINDOW
} from '@common/angular/config';
import {
  AH_USER_API_URL,
  PARAM_ESTIMATES_URL
} from '@common/angular/core/models';
import { CommonAngularErrorHandlingModule } from '@common/angular/error-handling';
import { CommonUserProfileModule } from '@common/angular/features/user-profile';
import { CommonAngularTranslationLoaderModule } from '@common/angular/translation';
import { CommonAngularUrlRouterModule } from '@common/angular/url-router';
import {
  CommonAngularUtilsModule,
  EmptyDateTransformInterceptor,
  TimezoneHttpInterceptor
} from '@common/angular/utils';
import { IfhmsAdminDomainStateRootModule } from '@ifhms/admin/domain/state/root';
import { IfhmsAdminWebCoreFormlyModule } from '@ifhms/admin/web/core/formly';
import { IfhmsAdminWebDomainNavigationModule } from '@ifhms/admin/web/domain/navigation';
import { IfhmsAdminWebDomainStateOperationModule } from '@ifhms/admin/web/domain/state/operation';
import { IfhmsAdminWebDomainStateReferenceDataModule } from '@ifhms/admin/web/domain/state/reference-data';
import {
  AH_MIGRATION_PROGRESS_SIGNALR_URL,
  AH_SIGNALR_URL,
  IfhmsCommonAngularApiMessageHubModule
} from '@ifhms/common/angular/api/message-hub';
import {
  AH_FMS_ADMIN_API_CONFIG,
  AH_FMS_DATA_SYNC_API_CONFIG,
  IfhmsCommonAngularDataAccessAdminApiModule
} from '@ifhms/common/angular/data-access/admin-api';

import {
  AH_FMS_REPORT_API_URL,
  AH_FMS_REPORT_SELECT_LIST_API_URL
} from '@ifhms/common/angular/data-access/report-api';
import { ANIMALS_API_URL } from '@ifhms/common/shared/formly/types/animal-selector';

import { AuthAppLevelRoles, ContextLevel } from '@ifhms/models/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';

import { AppConfigService } from '../services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const getSignalRUrl = (appConfig: AppConfigService): string | undefined => {
  return appConfig.getConfig().signalRUrl;
};

const getMigrationProgressSignalRUrl = (
  appConfig: AppConfigService
): string | undefined => {
  return appConfig.getConfig().migrationProgressSignalRUrl;
};

const getDataSyncApiUrl = (appConfig: AppConfigService): string | undefined => {
  return appConfig.getConfig().dataSyncApiUrl;
};

const getShowDevFeature = (appConfig: AppConfigService): boolean => {
  return appConfig.getConfig().showDevFeature;
};

const getAdminApiUrl = (appConfig: AppConfigService): string => {
  return appConfig.getFeedlotApiUrl();
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IfhmsAdminDomainStateRootModule,
    CommonUserProfileModule,
    CommonAngularTranslationLoaderModule,
    IfhmsAdminWebCoreFormlyModule,
    IfhmsCommonAngularApiMessageHubModule,
    IfhmsAdminWebDomainNavigationModule,
    IfhmsAdminWebDomainStateReferenceDataModule,
    ToastModule,
    ConfirmDialogModule,
    NgSelectModule,
    CommonAngularUrlRouterModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1Ijoiam9zaGx5bm4iLCJhIjoiY2xkMmw5dnQ5MDd5ejNxcjR0eDY3OGQ5cSJ9.4X9vhjP_HI4M68CQ--yXXw'
    }),
    CommonAngularAuthModule.forRoot({
      clientId: 'adminweb',
      scope: 'openid profile AnimalHealth.Fms.Admin.App.All AnimalHealth.DataSync.Service.All offline_access',
      forceUserLogin: true,
      restrictedRoleAccess: {
        levelDataKey: 'contextLevel',
        accessDeniedPath: '/auth/access-denied',
        levelsMap: [
          {
            level: ContextLevel.Enterprise,
            path: '/enterprise',
            requiredRoles: [AuthAppLevelRoles.Enterprise]
          },
          {
            level: ContextLevel.Company,
            path: '/companies-redirect',
            requiredRoles: [AuthAppLevelRoles.Company]
          },
          {
            level: ContextLevel.Operation,
            path: '/operations-redirect',
            requiredRoles: [
              AuthAppLevelRoles.Company,
              AuthAppLevelRoles.FRM_Operation,
              AuthAppLevelRoles.StockFlow_Operation
            ]
          }
        ]
      }
    }),
    IfhmsCommonAngularDataAccessAdminApiModule,
    IfhmsAdminWebDomainStateOperationModule,
    CommonAngularUtilsModule,
    CommonAngularErrorHandlingModule
  ],
  providers: [
    AppConfigService,
    DialogService,
    MessageService,
    ConfirmationService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_CONFIG,
      useFactory: getConfig,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_SERVER_URL,
      useFactory: getAuthServerUrls,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_API_URLS,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: 'apiUrl',
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_USER_API_URL,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_ADMIN_API_CONFIG,
      useFactory: getAdminApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_REPORT_API_URL,
      useFactory: getReportApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_REPORT_SELECT_LIST_API_URL,
      useFactory: getReportSelectListApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: ANIMALS_API_URL,
      useFactory: getAnimalsApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_SIGNALR_URL,
      useFactory: getSignalRUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_MIGRATION_PROGRESS_SIGNALR_URL,
      useFactory: getMigrationProgressSignalRUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_DATA_SYNC_API_CONFIG,
      useFactory: getDataSyncApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmptyDateTransformInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneHttpInterceptor,
      multi: true
    },
    {
      provide: SHOW_DEV_FEATURE,
      useFactory: getShowDevFeature,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: WINDOW,
      useFactory: getWindow,
      multi: false
    },
    {
      provide: PARAM_ESTIMATES_URL,
      useFactory: getAdminApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
